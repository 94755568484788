<template>
  <section class="container about">
    <div class="about__card row">
      <div class="col-12"><p class="about__title">НМТ - Національне мультипредметне тестування 2025</p></div>
      <div class="col-12"><p class="about__text">Підготовка до НМТ 2025 стала зручнішою! Здобувайте нові знання разом з найкращими вчителями міста.
      </p></div>

      <div class="col-12"><p class="about__text2">Готуйтесь до випробовування та вийвчайте такі дисципліни:</p></div>

      <div class="col-12">
        <ul class="about__subjects">
          <li>Українська мова</li>
          <li>Математика</li>
          <li>Історія України</li>
          <li>Біологія</li>
          <li>Фізика</li>
          <li>Хімія</li>
          <li>Іноземна мова (англійська, німецька, французька,іспанська)</li>
          <li>Українська література</li>
          <li>Географія</li>
        </ul>
      </div>

<!--      <div class="col-12"><p class="about__text2">На вибір один предмет:</p></div>-->

<!--      <div class="col-12">-->
<!--        <ul class="about__subjects">-->
<!--          <li>українська література</li>-->
<!--          <li>іноземна мова (англійська, німецька, <br> французька,іспанська)</li>-->
<!--          <li>біологія</li>-->
<!--          <li>фізика</li>-->
<!--          <li>хімія</li>-->
<!--          <li>географія</li>-->
<!--        </ul>-->
<!--      </div>-->

      <div class="col-12">
        <p class="about__text col-12">Одні з найкращих вчителів міста зробили записи усіх потрібних лекцій та розробили більше 1 500 тестів,
          які знадобляться для плідної підготовки до Національного мультипредметного тестування.
          Реєструйся за
          <a href="#form" class="fw-bolder">посиланням</a> вже зараз!
          <br>
          Починай готуватися до НМТ!
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.about__card {
  margin-top: calc(100% / 12);
}

.about {
  border-radius: 50px;
  background: #fff;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.3);
  font-family: DniproCity-Regular, -apple-system, sans-serif;
}

.about__title {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: 42px;
  padding: 20px 0 0 20px;
  line-height: 1.4;
}

.fw-bolder {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
//background: #8C9FD7; //color: white; //padding: 5px 10px; //border-radius: 30px;
}

.about__text {
  font-size: 32px;
  line-height: 1.4;
  padding-left: 20px;
  width: 95%;
}

.about__text2 {
  font-size: 32px;
  line-height: 1.4;
  padding-left: 20px;
  width: 95%;
}

.about__subjects {
  font-size: 27px;
  line-height: 1.23;
}

.about__subjects li {
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  margin-left: 30px;
  padding-top: 0;
}
</style>
